* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  outline: none;
}
.App{
  overflow: hidden;
}
body {
  margin: 0;
}
ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

h1 {
  text-transform: uppercase;
  text-align: center;
  margin: 20px;
  font-family: Quattrocento;
  color: #373737;
  font-weight: 400;
  text-transform: uppercase;
}

p,
a {
  font-family: Candara;
  font-weight: 400;
  color: #373737;
}

@font-face {
  font-family: Quattrocento;
  src: url("assets/fonts/Quattrocento/QUATTROCENTO-REGULAR.TTF")
    format("opentype");
}
@font-face {
  font-family: Candara;
  src: url("assets/fonts/Candara/CANDARA.TTF") format("opentype");
}
.card-title {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  /* padding-left: 1rem; */
}

.main_container {
  text-align: center;
}

.home-card-container div {
  align-items: flex-start;
}

.home-card-container {
  
  width: 20vw;
}

.menu_title {
  font-size: 27px;
  margin-bottom: 15px;
}

.my-masonry-grid {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  margin-left: -7vw;
  /* padding: 10px; */
  width: 100%;
}

.my-masonry-grid_column {
  padding-left: 20px;
  width: 45% !important;
  width: min-content;
  background-clip: padding-box;
}

.my-masonry-grid_column button {
  width: 95%;
}

.my-masonry-grid_column > div {
  background: grey;
  margin-bottom: 30px;
}

.loading_image_container {
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
  vertical-align: center;
}

.loading_image {
  width: 100vw;
  height: 200px;
}




/* Optional, different gutter size on mobile */
@media (min-width: 815px) {
  .my-masonry-grid {
    margin-left: -15px; /* gutter size offset */
    display: flex;
    justify-content: center;
  }
  .my-masonry-grid_column:first-child {
    margin-right: 40px;
  }
  .my-masonry-grid_column {
    padding-left: 25px; /* gutter size offset */
    width: unset;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .App{
    display: flex;
    justify-content: center;
  }
  .card-title{
    padding-left: 0;
  }
  .my-masonry-grid_column > button {
    /* width: 150px; */
    width: 88%;
  }
  .my-masonry-grid_column > div {
    margin-bottom: 15px; /* space between items */
  }
  .home-card-container{
    width: 15vw;
  }
  .home-card-container > div > img {
    width: 20vh;
  }
}

@media (max-width: 280px) {
  .my-masonry-grid {
    margin-left: -25px; 
    display: flex;
    justify-content: center;
  }

}

@media (min-width: 1024px) {
  .my-masonry-grid {
    margin-left: 120px; 
    display: flex;
    justify-content: center;
  }
 

  .my-masonry-grid_column button {
    width: 45%;
  }

}