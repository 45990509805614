.navbar {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  width: 100%;
  height: 15vh;
  z-index: 10000;
  position: relative;
}

.navbar_item {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.nav-logo {
  height: 60px;
}

.icon-home-link {
  z-index: 1000;
}

.show-nav {
  display: flex;
  justify-content: space-between;
}

.cross-menu {
  width: 20px;
}

.home-icon-fixed {
  z-index: 1000;
  top: 1.5vh;
}

.link_container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 33%;
  text-align: center;
  /* margin-bottom: 10px;
  margin-top: 10px; */
}
.link_container a {
  width: 100%;
}

.img_link_container {
  /* height: 70px;
  width: 60px; */
  max-width: 100px;
  margin-bottom: 10px;
  object-fit: scale-down;
}

.navbar-title{
  font-size: 14px;
}

.image-container {
  display: flex;
  justify-content: center;
  height: 100px;
}

.navbar_links {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  overflow: hidden;
  height: 90vh;
}

.navbar_link {
  columns: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: #373737;
}

.navbar_burger {
  width: 30px;
  height: 30px;
  background-color: transparent;
  border: none;
}

.navbar_arrowBack {
  border: none;
  background-color: transparent;
}

.navbar_links {
  flex-direction: row;
  justify-content: center;
  position: fixed;
  /* display: flex; */
  left: -100vw;
  bottom: 0;
  /* height: 100vh; */
  width: 0;
  visibility: hidden;
  align-items: center;
  background-color: white;
  transition: all 0.8s ease-out;
  flex-wrap: wrap;
  align-content: center;
}

.show-nav .navbar_links {
  left: 0;
  width: 100vw;
  visibility: visible;
  flex-wrap: wrap;
}

.navbar_link {
  flex-direction: column;
  padding: 5px;
  flex-wrap: wrap;
  font-size: 12px;
  box-sizing: border-box;
}

.navbar_burger {
  top: 3vh;
  left: 1rem;
}
.navbar_burger_hide {
  width: 30px;
  height: 30px;
  border: none;
  background-color: transparent;
  z-index: 1000;
}

.navbar_burger:hover {
  cursor: pointer;
}

.burger-bar,
.burger-bar::before,
.burger-bar::after {
  background-color: #639f8d;
  display: block;
  width: 30px;
  height: 2px;
  position: relative;
  border-radius: 3px;

  transition: all 0.5s ease-in-out;
}

.burger-bar::before,
.burger-bar::after {
  content: "";
  position: absolute;
  left: 0;
}

.burger-bar::before {
  transform: translateY(-12px);
}

.burger-bar::after {
  transform: translateY(12px);
}

.show-nav .burger-bar {
  width: 0;
  background: transparent;
}

.show-nav .burger-bar::before {
  transform: rotate(45deg);
}

.show-nav .burger-bar::after {
  transform: rotate(-45deg);
}

.back-arrow {
  border: none;
  right: 0;
  background-color: transparent;
}

.back-arrow-hide {
  visibility: hidden;
  border: none;
  right: 0;
  background-color: transparent;
}

.navbar-title-container {
  height: 30px;
}

@media (max-width: 280px) {
  .navbar_link {
    padding: 0;
  }

}

@media (max-height: 490px) {
  .navbar_item {
    justify-content: flex-end;
    align-items: flex-end;
    height: 75%;
  }

}


@media (min-width: 1024px) {

.navbar_link {
padding: 0;
}}
