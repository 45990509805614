.carousel {
  overflow: hidden;
  position: relative;
  width: 60vw;
  margin-left: 21vw;
}

.inner {
  white-space: nowrap;

  transition: transform 0.3s;
}

.carousel-item {
  display: inline-flex;
  align-items: center;
  justify-content: center;

  counter-reset: white;
}

.main-container {
  position: relative;
}

.prev_button,
.next_button {
  position: absolute;
  border: none;
  background-color: transparent;
}

.prev_button {
  left: 40px;
  bottom: 35%;
}

.next_button {
  right: 40px;
  bottom: 35%;
}
