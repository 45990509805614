.footer_image_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

h1 {
  margin: 0;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 15px;
}

.content {
  margin: 10vw;
}

.content img {
  margin-right: 10px;
  margin-top: 15px;
  margin-bottom: 15px;
  max-width: 100%;
  /* width: 100%; */
}

.same-categorie-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.same-categorie-div h1 {
  font-size: 12px;
}
.same-categorie-div button {
  border: none;
  background-color: transparent;
}

.footer_image_container img {
  margin-left: 30px;
  margin-right: 30px;
}

.footer_line {
  position: absolute;
  left: 20%;
}

.carousel-image {
  height: 60px;
  width: 60px;
}

.horoscope-image {
  width: 100vw;
  height: 92vh;
  object-fit: cover;
  position: relative;
}

.arrow-down {
  position: absolute;
  left: 45%;
  bottom: 10px;
}

.card_item img {
  object-position: unset;
}
