.card-container {
  display: flex;
  max-width: 40px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  white-space: normal;
}
.card-container p {
  font-family: Quattrocento;
  font-size: 10px;
  text-align: center;
  margin-top: 5px;
}

.carousel-image {
  object-fit: cover;
  border-radius: 8px;
}

.item-card-button {
  border: none;
  background-color: transparent;
}
